/* BOOTCAMP WALLPAPER */
.space-wallpaper{
  position: absolute;
  z-index: -5;
  /* Set rules to fill background */
  min-height: 100%;
  /* Set up proportionate scaling */
  height: auto;
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}
.cohortClassName {
  font-family: "FastHand";
}
.weywot{
  position: relative;
  z-index: 1;
  font-family: "FastHand";
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 50px;
  color: #ffff;
  font-size: 150px;
  }
.NFTJavier{
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.NFTcardHolders{
  padding-left: 30px;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-right: 30px;
}
.NFT-img{
  padding-top: 0px;
}
.card-nft:hover  {
  transform: scale(1.1);
}
.card-nft  {
  z-index: 2;
  padding-bottom:30px;
}
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
/* FONT HOVER */
.linkGithub{
  font-family: "FastHand";
}
/* Media query */
/* small devices Iphone */
@media only screen and (max-width: 460px){
  .weywot {
    color: #ffff;
    font-size: 80px;
    left:20px;
  }
  .NFTcardHolders {
    width: 475px;
  }
  .NFTJavier {
    margin-left: 60px;
  }
}
/* medium devices */
@media screen and (max-width: 500px) {
  .fullSizeOfNFTCard {
    width: 200px;
  }
  .homeTextCohort {
    font-size: 22px;
  }
  .aboutMeTextCohort {
    font-size: 22px;
  }
  .galleryTextCohort {
    font-size: 22px;
  }
  .projectsTextCohort {
    font-size: 22px;
  }
}
/* large devices */
@media screen and (max-width: 1440px){
  .fullSizeOfNFTCard {
    width: 200px;
  }
  .homeTextCohort {
    font-size: 22px;
  }
  .aboutMeTextCohort {
    font-size: 22px;
  }
  .galleryTextCohort {
    font-size: 22px;
  }
  .projectsTextCohort {
    font-size: 22px;
  }
}