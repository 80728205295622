/*! WALLPAPER */
.gallery-page-wrapper {
    background-image: url(../../img/Wallpaper/abstract-background.jpg);
    background-size: cover;
}
/*! FONT SIZE */
.galleryNightzillla{
    font-family: "SpaceRanger";
    color:#ffff;
    font-size: 40px;
    margin-left: 4rem;
}
.navbarHeadingColor{
    color:#ffff;
}
.navbarText{
    color:#ffff;
    margin-left: 25px;
}
.navbarText:hover{
    transform: scale(1.1);
}
.img-card {
    width: 25rem;
}

  /*! MEDIA QUERIES */
  /* small devices */
  @media only screen and (max-width: 600px){
   
  }
  /* small devices */
  @media only screen and (max-width: 576px){
  }
  /* medium devices */
  @media screen and (min-width: 960px) {
  }
  /* large devices */
  @media screen and (min-width: 1440px){
  }

  /*! TESTING MEDIA QUERIES */
  @media screen and (min-width: 600px) {
}

@media screen and (min-width: 768px){
    .img-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 992px){
    .img-container {
        grid-template-columns: repeat(3, 1fr);
    }
}