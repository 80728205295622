.navbarColor {
    background-color: rgb(25, 25, 25);
}
.basic-navbar-nav{
    justify-items: start;
}
.navbar-collapse {
    display: flex;
    flex-grow: 0;
}
/* MEDIA QUERIES*/
/* small devices */
@media only screen and (max-width: 1500px){
    .navbar-collapse{
        margin-left: 40px;
    }
    .navbarHeadings {
        margin-left: 0px;
    }
}