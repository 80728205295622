@tailwind base;
@tailwind components;
@tailwind utilities;
/* Custom font for webpage */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@300&family=Sono:wght@200&display=swap');
@font-face {
    font-family: "NewAcademy";
    src: local("NewAcademy"), url("./fonts/NEWACADEMY.ttf")format("truetype");
    font-weight: bold;
}
@font-face {
  font-family: "Valorax";
  src: local("Valorax"), url("./fonts/Valorax.otf")format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "SpaceRanger";
  src: local("SpaceRanger"), url("./fonts/SpaceRangerHalftoneItalic.ttf")format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "SpaceRanger2";
  src: local("SpaceRanger2"), url("./fonts/SpaceRangerLaserItalic.ttf")format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "FastHand";
  src: local("FastHand"), url("./fonts/FastHand.ttf")format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Beyno";
  src: local("Beyno"), url("./fonts/BEYNO.ttf")format("truetype");
  font-weight: bold;
}
/*! Navbar Font and Hover*/
.navbarHeadings{
  font-family: "SpaceRanger2";
  font-size: 30px;
}
.navbarHeadings {
  color:#ffff;
}
.homePageNightzillla {
  font-family: "SpaceRanger";
  color:#ffff;
  font-size: 40px;
  margin-left: 4rem;
}
.AboutMeText {
  margin-left: 25px;
}
.AboutMeText:hover {
  transform: scale(1.1);
  color:#ffff;
}
.GalleryText {
  margin-left: 25px;
}
.GalleryText:hover {
  transform: scale(1.1);
  color:#ffff;
}
.cohortText {
  margin-left: 25px;
}
.cohortText:hover {
  transform: scale(1.1);
  color:#ffff;
}
.cohortGalleryText{
  margin-left: 25px;
}
.cohortGalleryText:hover {
  transform: scale(1.1);
  color:#ffff;
}
@keyframes preloader {
  100% { transform: scale(2); }
}

