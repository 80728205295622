/* BACK GROUND IMAGE */
.page-wrapper-project {
    background-image: url(../../img/Wallpaper/abstract-background.jpg);
    background-size: cover;
}
.abstractImg{
    z-index: -5;
    position: absolute;
    background-size: cover;
}
/* Buttons */
.linkDIcon:hover {
    color: black;
}
.githubIcon:hover{
    color: black;
}
.photoGalleryIcon:hover {
    color: black;
}
.bi-play-btn-fill {
    color:rgb(157, 163, 174);
}
.bi-play-btn-fill:hover {
    color: black;
}
.bi-code-square {
    color:rgb(157, 163, 174);
    margin-top: .2rem;
}
.bi-code-square:hover {
    color: black;
}
/* FONT SIZE */
.projectTitle {
    font-family: "NewAcademy";
    color:black;
}
.fsd {
    font-family: "NewAcademy";
    color: black;
}
.projectNightzillaFont {
    font-family: "SpaceRanger";
    font-size: 25px;
}
.homeProjectNavBar {
    font-family: "SpaceRanger2";
    font-size: 20px;
    padding-right: 10px;
    padding-top: 5px;
}
.homeProjectNavBar:hover{
    transform: scale(1.1)!important;
    color: black;
}
.cohortNavBar {
    font-family: "SpaceRanger2";
    font-size: 20px;
    padding-top: 5px;
}
.cohortNavBar:hover {
    transform: scale(1.1);
    color: black;
}
.projectGalleryNavBar {
    font-family: "SpaceRanger2";
    font-size: 20px;
    padding-top: 5px;
}
.projectGalleryNavBar:hover {
    transform: scale(1.1);
    color: black;
}
.img-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    padding: 0 30px;
}
.wrapper {
    width: 33.3%;
    height: 100%;
    padding: 10px;
}
  
.box {
    position: relative;
    max-height: 70vh;
    border-radius: 4px;
    overflow: hidden;
}
  /* MEDIA QUERIES */
  /* small devices */
  @media only screen and (max-width: 600px){
    .danny-Profile-Card {
        padding-left: none;
    }
    .projectNightzillaFont {
        font-size: 20px;
    }
    .homeProjectNavBar {
        font-size: 16px;
    }
    .projectGalleryNavBar{
        font-size: 16px;
    }
    .cohortNavBar {
        font-size: 16px;
    }
  }
  /* medium devices */
  @media screen and (min-width: 960px) {
    /* Custome Fonts */
    .galleryNightzillaFont {
        padding-right: 620px;
        font-size: 30px;
    }
    .homeGalleryNavBar {
        font-size: 20px;
    }
    .aboutMeNavBar {
        font-size: 20px;
    }
    .cohortNavBar {
        font-size: 20px;
    }
    .galleryNavBar {
        font-size: 20px;
    }
  }
 