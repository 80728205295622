/*! Main Page */
.welcomeHeader{
    position: relative;
    font-family: "SpaceRanger";
    font-size: 40px;
    color:#ffff;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin-top: 300px;
    transition: opacity 1 ease-in-out;
    -webkit-transition: opacity 1 ease-in-out;
    -moz-transition: opacity 1 ease-in-out;
    -ms-transition: opacity 1 ease-in-out;
    -o-transition: opacity 1 ease-in-out;
}
.loading-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.homepageNavBar{
    position: relative;
}
/* Video */
.video-container {
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}
.video-container, .welcomeHeader {
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-ou;
    -moz-transition: opacity 0.5s ease-in-ou;
    -ms-transition: opacity 0.5s ease-in-ou;
    -o-transition: opacity 0.5s ease-in-ou;
}
.video-container.fade-in {
    opacity: 1;
}
.video-container.fade-out{
    /* display: none; */
    opacity: 0;
}
  
.fade-in {
    opacity: 1;
}
  
.fade-out {
    opacity: 0;
}
.hidden {
    display: none; 
}
.notHidden {
    display: flex;
    align-items: center;
    justify-content: center;
}
.video {
    position: absolute;
    width: 100vw;
    object-fit: fill;
    height: 100%;
    top: 0;
    left: 0;
}
.smoke {
    height: 100%;
    width: 100%;
}
/* Video Component*/
.video-container {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.video-container video {
    position: absolute;
    height: 125%;
    min-width: 100%;
    object-fit: cover;
}
/*! HomePageContent */
 
.homePageContentContainer {
    display: flex;
    position: relative;
    height: 100vh;
}

.imageContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }
.orangeSmoke {
    /* position: absolute; */
    object-fit: cover;
    width: auto;
    height: 100%;
    z-index: 1;
}
 .blackBackground {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(25, 25, 25);
}
.textContainer {
    font-family: Anton;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin-bottom: 5px;
    top: 50%;
    left: 35%;
    z-index: 2;
    transform: translate(-50%, -50%);
    color: #ffff;
    text-align: start;
    font-size: 50px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.textContainerSmall {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    font-family: Sono;
    width: 100%;

}
.texasFlag {
    margin-top: 2px;
    margin-left: 5px;
    max-width: 15px;
    max-height: 10px;
}
/*! Profile Links */
.linksBio {
    display: flex;
    margin-top: 10px;
}
.githubLink {
    margin-left: 10px;
}
.photoGallery {
    margin-left: 10px;
}
.linkD:hover {
    color: grey;
}
.githubLink:hover {
    color: grey;
}
.photoGallery:hover{
    color: grey;
}

/* MEDIA QUERIES*/
/* small devices */
@media screen and (max-width: 600px) {
    .textContainer{
        top: 310px;
        left: 50%;
    }
    .welcomeHeader {
        left: 15%;
    }
}
/* medium devices */
@media screen and (min-width: 500px) {
    /* .textContainer{
        left: 50%;
    } */
}
/* x-large devices */
@media screen and (min-width: 1500px) {
    .textContainer {
        left: 33%;
    }

}